<template>
  <div>
    <div class="menu-bar-left">
      <VueButton
        @click="onBackButtonClick"
        color="grey-50"
        :iconName="btnBackIcon.name"
        :iconSize="{
          width: btnBackIcon.width,
          height: btnBackIcon.height,
        }"
        :iconColor="backButtonColor"
        :ghost="true"
        target="-1"
      ></VueButton>
    </div>
    <div class="menu-bar-right">
      <VueText sizeLevel="8" weightLevel="4" class="back-btn-title">Ayarlar</VueText>
    </div>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import StorageProps from '@/mixins/storageProps.js';
import { mapGetters } from 'vuex';

export default {
  name: 'VueAppbar',
  mixins: [StorageProps],
  props: {
    title: {
      type: String,
      default: '',
    },
    hasBackButton: {
      type: Boolean,
      default: false,
    },
    backButtonColor: {
      type: String,
      default: '#000000',
    },
    triggerBackButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  components: {
    VueButton,
    VueText,
  },
  computed: {
    btnBackIcon() {
      return COMPONENT_CONSTANTS.ICON_VARIABLES.arrowLeft;
    },
    ...mapGetters('app', ['getAppbarConfig', 'getFunPageModalStatus']),
    ...mapGetters('auth', ['getPos']),
  },
  methods: {
    onBackButtonClick() {
      if (this.triggerBackButton && this.backButtonEvent) {
        this.$emit('backButtonEvent');
        this.$router.go(-1);
        return;
      }

      if (this.triggerBackButton) {
        this.$router.go(-1);
      } else {
        this.$emit('backButtonEvent');
      }
      return;
    },
  },
};
</script>

<style lang="scss">
.menu-bar-left {
  cursor: pointer;
}
.menu-bar-right {
  .back-btn-title {
    padding: 0 10px;
  }
}
</style>
