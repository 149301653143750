<template>
  <div class="wrapper" @click="goToVirtualVisitUrl">
    <img :src="imgSrc" alt="chatbot" />
    <div class="text-content-wrapper">
      <div class="text-content">
        <VueText sizeLevel="7" weightLevel="5">Şef Dükkanında&nbsp;</VueText>
        <VueText sizeLevel="7" weightLevel="2">İzle</VueText>
      </div>
    </div>
  </div>
</template>
<script>
import VueText from 'VueText';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import gtmUtils from '@/mixins/gtmUtils.js';
export default {
  name: 'ButtonVirtualVisit',
  components: {
    VueText,
  },
  mixins: [gtmUtils],
  computed: {
    imgSrc() {
      return require('../../../assets/button/virtualvisit-sef.png');
    },
  },
  methods: {
    goToVirtualVisitUrl() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('sef_dukkaninda', {
        location: 'sidebar',
      });
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.VirtualVisit.path}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: bottom;
  background: url('~@/assets/button/virtualvisit-bg.png');
  margin-bottom: palette-space-level(10) !important;
  background-repeat: no-repeat;
  background-position-y: bottom;
  cursor: pointer;
  > img {
    margin-left: palette-space-level(8) !important;
  }
  .text-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: palette-space-level(20);
    padding-left: palette-space-level(10);
    .text-content {
      display: flex;
      flex-direction: row;
      user-select: none;
      color: #fff;
      p {
        &:first-child {
          margin-bottom: palette-space-level(3);
        }
      }
    }
  }
}
</style>
