<template>
  <div class="cookie-modal" v-if="isActive">
    <div class="cookie-modal-content">
      <div class="info-container">
        <VueText class="cookie-modal-wrapper-text" sizeLevel="3" color="grey-30" weightLevel="2"
          >Bu sitede çerezler (ve benzeri teknolojiler) kullanmaktayız.
          <b class="cookies-link" @click="goCookies">Daha fazla bilgi al.</b> Bu çerezler üç
          kategoriye ayrılmaktadır ve bu kategoriler aşağıda açıklanmıştır. Tarafımızdan gelen bu
          çerezleri kabul edip etmeyeceğinize karar verebilirsiniz.
          <span class="underlined" @click="goDataPolicy">Gizlilik Bildirimi</span>
        </VueText>
        <div class="cookie-modal-actions">
          <router-link class="cookie-modal-actions-more" to="/secure/statics/cookie-policy"
            >Daha Fazla Bilgi Al</router-link
          >
          <a class="cookie-modal-actions-more" @click="acceptCookies()">Kabul Ediyorum</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesStatic from '@/router/routes/secure/RoutesStatic';
import RoutesSettings from '@/router/routes/secure/RoutesSettings';

export default {
  name: 'BrandCookieModal',
  components: {
    VueText,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    acceptCookies() {
      this.$emit('acceptedCookies');
    },
    goCookies() {
      this.$router.push(
        `${RoutesRoot.Secure.path}/${RoutesSecure.Statics.path}/${RoutesStatic.CookiePolicy.path}`,
      );
    },
    goDataPolicy() {
      this.$router.push(
        `${RoutesRoot.Secure.path}/${RoutesSecure.Settings.path}/${RoutesSettings.DataPolicy.path}`,
      );
    },
  },
  watch: {
    showModal: {
      handler: function(val) {
        this.isActive = val;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.cookie-modal {
  overflow: auto;
  position: fixed;
  width: 100vw;
  max-height: 250px;
  z-index: 9998;
  background-image: linear-gradient(
    315deg,
    rgba(palette-color-level('fushia', '20'), 0.6),
    rgba(palette-color-level('yellow', '30'), 0.6)
  );
  padding-top: 1px;
  bottom: 0;
  left: 0;

  &-content {
    padding: 20px;
    position: relative;
    display: block;
    width: 100%;
    background-color: #f7f8fa;
    border-radius: palette-radius-level('2');
  }
  &-wrapper {
    &-text {
      overflow: auto;
      color: palette-color-level('grey', 30) !important;
      line-height: 1.83;
    }
  }
  &-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: palette-space-level('20');
    font-size: 14px;
    text-decoration: underline;
    color: #fff;
    &-more {
      // padding-right: palette-space-level('60');
      color: palette-color-level('grey', 40) !important;
      padding: palette-padding-level(15, 20);
    }
  }
}
.cookies-link {
  cursor: pointer;
}

.underlined {
  font-size: 10px;
  font-weight: 600;
  margin-left: 10px;
  color: #79838e;
  text-decoration: underline;
  cursor: pointer;
}
</style>
