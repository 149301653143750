<template>
  <div class="card-container">
    <div v-if="items && items.length > 0" class="slider-container">
      <swiper :options="swiperOptions">
        <swiper-slide
          v-for="(slide, index) in items"
          :key="`${slide.bannerId}_${index}`"
          class="swiper-slide"
        >
          <div class="slide">
            <div class="slide-wrapper" @click="goTo($event, slide, 'redirect')">
              <div class="top-row-content">
                <img
                  :style="{
                    cursor: slide.isDigitalAlliance ? 'pointer' : 'unset',
                  }"
                  :src="slide.image"
                  alt="do-win-partner"
                  @click="goToPartners($event, slide)"
                />
              </div>
              <div v-if="slide.isDigitalAlliance && !isLampUser" class="middle-row-content">
                <div
                  class="row-item"
                  :style="slide.partner.partnerCode === 'PLADIS' && 'align-items: center'"
                >
                  <div class="row-item-header">
                    <VueIcon
                      :iconName="constants.icons.truck.name"
                      :width="constants.icons.truck.width"
                      :height="constants.icons.truck.height"
                      :iconColor="constants.icons.truck.color"
                    ></VueIcon>
                    <VueText :isSingleLine="true" color="grey-30" sizeLevel="3" weightLevel="3">
                      {{
                        slide.partner.partnerCode === 'PLADIS'
                          ? 'SONRAKİ ZİYARET (SATIŞ)'
                          : 'SONRAKİ ZİYARET'
                      }}</VueText
                    >
                  </div>
                  <div class="row-item-value">
                    <VueText sizeLevel="4" weightLevel="4">{{ slide.nextVisit }}</VueText>
                  </div>
                </div>
                <div
                  v-if="slide.partner.partnerCode === 'PMI'"
                  class="row-item wallets"
                  :style="
                    userType === 'KACC' || isLampUser ? 'visibility:hidden;' : 'visibility:visible;'
                  "
                  @click="goTo($event, routes.wallets, null, 'cuzdanlarim')"
                >
                  <div class="row-item-header">
                    <VueIcon
                      class="ico-wallet"
                      :iconName="constants.icons.wallet.name"
                      :width="constants.icons.wallet.width"
                      :height="constants.icons.wallet.height"
                      :iconColor="constants.icons.wallet.color"
                    ></VueIcon>
                    <VueText :isSingleLine="true" color="grey-30" sizeLevel="3" weightLevel="3">
                      CÜZDAN</VueText
                    >
                  </div>
                  <div class="row-item-value">
                    <VueText sizeLevel="4" weightLevel="4">
                      {{ slide.amount | currencyFormat }}TL</VueText
                    >
                  </div>
                </div>
              </div>
              <div v-else class="middle-row-content">
                <div class="row-item">
                  <div class="row-item-header">
                    <VueText sizeLevel="5" weightLevel="4">{{ slide.title }}</VueText>
                  </div>
                  <div class="row-item-value">
                    <VueText sizeLevel="4" weightLevel="3" color="grey-30">{{
                      slide.detail
                    }}</VueText>
                  </div>
                </div>
              </div>
              <div class="bottom-row-content">
                <BrandButton
                  v-if="
                    slide.partner.partnerCode === 'PLADIS' || slide.partner.partnerCode === 'PEY'
                  "
                  :size="constants.size.xSmall"
                  :iconColor="slide.taskCount > 0 ? '#fff' : '#b4c2d3'"
                  shape="rounded"
                  :outlined="true"
                  class="doWin-button"
                  :radius="50"
                  @click="
                    goTo(
                      $event,
                      slide.partner.partnerCode === 'PEY'
                        ? routes.partnersPeyman
                        : slide.partner.partnerCode === 'PLADIS'
                        ? routes.partnersUlker
                        : routes.feed,
                      null,
                      'hakkinda',
                      slide.partner.description,
                    )
                  "
                >
                  <VueText :isSingleLine="true" sizeLevel="4" weightLevel="4">HAKKINDA</VueText>
                </BrandButton>
                <BrandButton
                  v-if="slide.partner.partnerCode === 'PMI'"
                  :size="constants.size.xSmall"
                  :iconColor="slide.taskCount > 0 ? '#fff' : '#b4c2d3'"
                  shape="rounded"
                  :iconName="constants.icons.waveHome.name"
                  :iconSize="{
                    width: constants.icons.waveHome.width,
                    height: constants.icons.waveHome.height,
                  }"
                  class="doWin-button"
                  :class="{ purpleButton: slide.taskCount > 0, empty: slide.taskCount <= 0 }"
                  :radius="50"
                  @click="goTo($event, routes.dowin, null, 'yap_kazan')"
                >
                  <VueText :isSingleLine="true" color="white-100" sizeLevel="4" weightLevel="4">
                    YAP KAZAN</VueText
                  >
                  <span :class="slide.taskCount ? 'doWin-count' : 'doWin-count doWin-count-0'">
                    <VueText :isSingleLine="true" color="grey-50" sizeLevel="4" weightLevel="4">
                      {{ slide.taskCount }}</VueText
                    ></span
                  >
                </BrandButton>
                <BrandButton
                  :size="constants.size.xSmall"
                  :outlined="true"
                  shape="rounded"
                  :iconName="constants.icons.priceListHome.name"
                  :iconSize="{
                    width: constants.icons.priceListHome.width,
                    height: constants.icons.priceListHome.height,
                  }"
                  iconColor="#E5472D"
                  class="doWin-button"
                  :radius="50"
                  @click="
                    goTo(
                      $event,
                      slide.partner.partnerCode === 'PMI'
                        ? routes.pmiPriceList
                        : slide.partner.partnerCode === 'PLADIS'
                        ? routes.pladisPriceList
                        : slide.partner.partnerCode === 'PEY'
                        ? routes.peymanPriceList
                        : routes.feed,
                      null,
                      'fiyat_listesi',
                      slide.partner.description,
                    )
                  "
                >
                  <VueText :isSingleLine="true" sizeLevel="4" weightLevel="4">
                    FİYAT LİSTESİ</VueText
                  >
                </BrandButton>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-next" slot="button-next"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
      </swiper>
      <slot />
    </div>
  </div>
</template>
<script>
import VueText from 'VueText';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import VueIcon from '@/components/shared/VueIcon/VueIcon';
import { Common } from '@/services/Api/index';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';
import StorageProps from '@/mixins/storageProps.js';
import currencyFilter from '@/mixins/currencyFilter.js';
import RoutesPmi from '@/router/routes/secure/businessPartners/RoutesPmi';
import RoutesUlker from '@/router/routes/secure/businessPartners/RoutesUlker';
import RoutesPeyman from '@/router/routes/secure/businessPartners/RoutesPeyman';
import StorageHelper from '@/utils/storageHelper';
import { mapGetters } from 'vuex';
import AccountMixin from '@/utils/accountUtils.js';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'DoWinCard',
  mixins: [StorageProps, currencyFilter, AccountMixin, gtmUtils],
  data() {
    return {
      userType: new StorageHelper({ id: process.env.VUE_APP_STORAGE_USER_ACCOUNT_TYPE }).get(),
      items: null,
      config: null,
      swiperOptions: {
        autoplay: { delay: 5000, disableOnInteraction: true },
        slidesPerView: 1.12,
        centeredSlides: true,
        spaceBetween: 12,
        resistance: true,
        resistanceRatio: 0.6,
        grabCursor: true,
        watchOverflow: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  components: {
    BrandButton,
    VueText,
    swiper,
    swiperSlide,
    VueIcon,
  },
  created() {
    this.getBanners();
  },
  watch: {
    getDoWinCardRefresh: {
      handler(value) {
        if (value) {
          this.getBanners();
        }
      },
    },
  },
  methods: {
    setSwiperOptions(options) {
      if (options) {
        if (options.bannerSliderType) {
          if (options.bannerSliderType.type === 3 && options.period > 0) {
            this.swiperOptions.autoplay.delay = options.period * 1000;
            this.autoPlay = false;
          } else {
            // delete this.swiperOptions.autoplay;
          }

          if (options.bannerSliderType.type === 1) {
            this.swiperOptions.resistance = true;
            this.swiperOptions.resistanceRatio = 0;
          }
        }
      }
    },
    goToPartners(event, item) {
      console.log(item.partner);
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('business_partner_click', {
        partner: item?.partner?.description,
      });
      if (item.isDigitalAlliance) {
        if (item.partner.partnerCode === 'PMI' && this.$route.path !== this.routes.partnersPmi) {
          event.stopPropagation();
          this.$router.push(this.routes.partnersPmi);
        }
        if (
          item.partner.partnerCode === 'PLADIS' &&
          this.$route.path !== this.routes.partnersUlker
        ) {
          event.stopPropagation();
          this.$router.push(this.routes.partnersUlker);
        }
        if (item.partner.partnerCode === 'PEY' && this.$route.path !== this.routes.partnersPeyman) {
          event.stopPropagation();
          this.$router.push(this.routes.partnersPeyman);
        }
      }
    },
    goTo(event, target, redirect, type, partner) {
      if (type === 'yap_kazan') {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('yap_kazan', {
          location: 'partner-slider',
        });
      } else if (type === 'hakkinda' || type === 'fiyat_listesi') {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent(type + '_click', {
          location: 'partner-slider',
          partner: partner,
        });
      } else if (type === 'cuzdanlarim') {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('cuzdanlarim', {
          location: 'partner-slider',
        });
      }
      if (target !== this.$route.path) {
        if (target && redirect) {
          if (!target.isDigitalAlliance && target.link) {
            window.location = target.link;
          }
        } else {
          event.stopPropagation();
          if (target !== this.$route.fullPath) {
            this.$router.push(target);
          }
        }
      }
      this.$emit('doWinEvents', type === 'yap_kazan');
    },
    getBanners() {
      Common.getPartnerBanners(this.customerCode).then(res => {
        if (res.data && res.data.Data) {
          if (res.data.Data.config) {
            this.config = res.data.Data.config;
            this.setSwiperOptions(this.config);
          }
          this.items = res.data.Data.partnerBanners;
          let count = res.data.Data.partnerBanners[0].taskCount;
          this.$store.dispatch('app/setActiveDowinCount', count);
          new StorageHelper({
            id: process.env.VUE_APP_STORAGE_TASK_COUNT,
          }).set(count);
        }
        this.$store.dispatch('app/setDoWinCardRefresh', false);
      });
    },
  },
  computed: {
    ...mapGetters('app', ['getDoWinCardRefresh']),
    constants() {
      return {
        size: COMPONENT_CONSTANTS.COMPONENT_SIZES,
        icons: {
          waveHome: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.waveHome, color: '#ffffff' },
          arrowLeft: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.leftArrow, color: '#b4c2d3' },
          arrowRight: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.rightArrow, color: '#b4c2d3' },
          truck: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.truck, color: '#79838e' },
          wallet: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.wallet, color: '#79838e' },
          priceListHome: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.priceListHome, color: '#E5472D' },
        },
      };
    },
    routes() {
      return {
        dowin: `${RoutesRoot.Secure.path}/${RoutesSecure.DoAndWin.path}`,
        playwin: `${RoutesRoot.Secure.path}/${RoutesSecure.PlayAndWin.path}`,
        wallets: `${RoutesRoot.Secure.path}/${RoutesSecure.MyWallets.path}?tab=discount-card`,
        pmiPriceList: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/pmi/${RoutesPmi.PriceList.path}`,
        pladisPriceList: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/ulker/${RoutesUlker.List.path}`,
        peymanPriceList: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/peyman/${RoutesPeyman.List.path}`,
        partnersPmi: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/pmi`,
        partnersUlker: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/ulker`,
        partnersPeyman: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/peyman`,
        feed: `${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.card-container {
  .slider-container {
    .swiper-button-next,
    .swiper-button-prev {
      height: palette-space-level('20');
      width: 13px;
      background-size: 13px palette-space-level('20');
      outline: none;
    }
    .swiper-button-next {
      background-image: url('~@/assets/icons/arrow-right.svg') !important;
      right: 0;
    }
    .swiper-button-prev {
      background-image: url('~@/assets/icons/arrow-left.svg') !important;
      left: 0;
    }
    .slide {
      padding-left: palette-space-level('20');
      padding-right: palette-space-level('20');

      .slide-wrapper {
        min-height: 310px;
        padding: palette-space-level('20');
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);

        div {
          display: flex;
          justify-content: center;
        }
        .top-row-content {
          overflow: hidden;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 100%;
            max-height: 100%;
            cursor: pointer;
          }
        }
        .middle-row-content {
          min-height: 90px;
          padding-top: palette-space-level('20');
          padding-bottom: palette-space-level('20');
          display: flex;
          justify-content: space-evenly;
          align-items: flex-start;
          .row-item {
            &:first-child {
              margin-right: palette-space-level('10');
              align-items: center;
            }
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .row-item-header {
              display: flex;
              align-items: center;
              min-height: 20px;
              svg {
                &:not(.ico-wallet) {
                  margin-right: palette-space-level('5');
                }
              }
              margin-bottom: palette-space-level('5');
              .ico-wallet {
                transform: scale(0.65);
                transform-origin: left;
              }
            }
          }
          .wallets {
            cursor: pointer;
          }
        }
        .bottom-row-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: auto;
          margin-bottom: 30px;
        }
        .doWin-button {
          max-width: 150px;
          min-height: 44px;
          max-height: 44px;
          padding-left: palette-space-level('10');
          padding-right: palette-space-level('10');
          &:not(:last-child) {
            margin-bottom: palette-space-level('10');
          }
          &:last-child {
            min-height: 42px;
            max-height: 42px;
          }

          .doWin-count {
            padding-top: 2px;
            padding-right: 2px;
            margin-bottom: 2px;
            height: 21px;
            width: 20px;
            margin-left: palette-space-level('5');
            display: flex;
            align-items: center;
            justify-content: center;
            background-repeat: no-repeat;
            background-position: center;
            background: url('~@/assets/button/count-holder.svg');
            background-size: contain;
            &-0 {
              padding-right: 1px;
              background: url('~@/assets/button/count-holder0.svg');
              p {
                margin-left: 1px;
              }
            }
          }
        }
      }
    }
  }
}
.swiper-container {
  padding: 10px 0;
}
/deep/ .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 23px;
  .swiper-pagination-bullet {
    opacity: 0.2;
    background-color: palette-color('grey-30');
    height: 6px;
    width: 6px;
    transform: scale(1);
    &-active {
      opacity: 0.5;
      background-color: palette-color('grey-40');
    }
  }
}

.purpleButton {
  @include bg-linear-gradient(
    to left,
    palette-color-level('fushia', '0') 0%,
    palette-color-level('purple', '40') 100%
  );
}
.empty {
  &.doWin-button {
    background: none;
    border: solid 1px #b4c2d3;
    p,
    span {
      color: #b4c2d3;
    }
    svg g {
      fill: #b4c2d3;
    }
    .doWin-count {
      border: solid 1px;
      border-radius: 50%;
    }
  }
}
</style>
