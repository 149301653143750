<template>
  <div v-if="getCurrentMarathon">
    <div class="marathon-container" @click="goToMarathon()">
      <BrandLogo
        :width="330"
        :height="180"
        :hasBorder="false"
        :image="getCurrentMarathon.imageURL"
      />
    </div>
    <MarathonDetailCard />
  </div>
</template>
<script>
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import RoutesPlayAndWin from '@/router/routes/secure/RoutesPlayAndWin';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo';
import Gamification from '@/services/Api/gamification';
import { mapState } from 'vuex';
import gtmUtils from '@/mixins/gtmUtils.js';
import StorageHelper from '@/utils/storageHelper';
import { mapGetters } from 'vuex';
import MarathonDetailCard from '@/components/brand/SideBar/MarathonDetailCard';

export default {
  name: 'MarathonCard',
  components: {
    BrandLogo,
    MarathonDetailCard,
  },
  mixins: [gtmUtils],
  data() {
    return {
      marathon: null,
    };
  },
  watch: {
    triggerRefresh: {
      handler(refresh) {
        if (refresh) {
          this.getMarathon(true);
        }
      },
    },
  },
  created() {
    this.getMarathon();
  },
  methods: {
    getMarathon(setRefresh) {
      if (setRefresh) {
        this.$store.dispatch('app/setRefreshMarathonBanner', false);
      }
      Gamification.getFeedMarathon()
        .then(res => {
          if (res.data?.Data?.dailyMarathon) {
            const {
              Data: { dailyMarathon },
            } = res.data;
            this.$store.dispatch('app/setCurrentMarathon', dailyMarathon);
          } else {
            this.$store.dispatch('app/setCurrentMarathon', null);
          }
        })
        .catch(() => {
          this.$store.dispatch('app/setCurrentMarathon', null);
        });
    },
    async goToMarathon() {
      if (this.getCurrentMarathon.marathonName === 'Ödül Yağmuru') {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('odül_yagmuru', {
          location: 'sidebar',
        });
      }
      if (this.$router.currentRoute.name !== RoutesPlayAndWin.PlayAndWinDetail.name) {
        new StorageHelper({ id: 'PlayableInfo' }).set(this.getCurrentMarathon);
        this.$router.push(this.getCurrentMarathon.redirectUrl);
      }
      this.$emit('marathonEvents', true);
    },
  },
  computed: {
    ...mapGetters('app', ['getCurrentMarathon']),
    ...mapState({
      triggerRefresh: state => state.app.marathonCardRefresh,
    }),
    constants() {
      return {
        size: COMPONENT_CONSTANTS.COMPONENT_SIZES,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.marathon-container {
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  .marathon-banner {
    background-size: 92%;
    background-position: center;
    background-repeat: no-repeat;
  }
  img {
    border-radius: palette-radius-level(2);
  }
}
</style>
