<template>
  <LayoutSecure id="divMainLayoutWrapper">
    <router-view></router-view>
  </LayoutSecure>
</template>
<script>
import LayoutSecure from '@/views/layouts/LayoutSecure.vue';

export default {
  name: 'Secure',
  components: {
    LayoutSecure,
  },
};
</script>
