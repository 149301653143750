<template>
  <div class="wrapper" @click="openChatbot()" id="sen_sor_wrapper">
    <img :src="imgSrc" alt="chatbot" />
    <div class="text-content">
      <VueText sizeLevel="7" weightLevel="2">7/24 Şef Destek Hattı</VueText>
    </div>
  </div>
</template>
<script>
import VueText from 'VueText';
import gtmUtils from '@/mixins/gtmUtils';
import StorageProps from '@/mixins/storageProps.js';
import { EventLog } from '@/services/Api/index';
import { User } from '@/services/Api/index';

export default {
  name: 'ButtonChatbot',
  mixins: [gtmUtils, StorageProps],
  components: {
    VueText,
  },
  computed: {
    imgSrc() {
      return require('../../../assets/button/chatbot-headphones.svg');
    },
  },
  methods: {
    openChatbot() {
      EventLog.postEventLog(this.$route.path, window.location.href);
      User.newsessionforChatbot();

      this.pushDataLayerEvent('chatbot');
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('sen_sor', {
        location: 'sidebar',
      });

      const chatBotEl = document.querySelectorAll('.container__CompanyIcon-sc-qtelua-10');
      chatBotEl.forEach(element => {
        element.click();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: bottom;
  background: url('~@/assets/button/chatbot-bg.svg');
  margin-bottom: palette-space-level(10) !important;
  background-repeat: no-repeat;
  background-position-y: bottom;
  cursor: pointer;
  > img {
    margin-left: palette-space-level(8) !important;
  }
  .text-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: palette-space-level(20);
    padding-left: palette-space-level(10);
    user-select: none;
    color: #fff;
    p {
      &:first-child {
        margin-bottom: palette-space-level(3);
      }
    }
  }
}
</style>
