<template>
  <div id="divSelectPosWrapper">
    <VueForm v-if="getPosList && getPosList.length" :bodyPadding="['0', '30']">
      <VueText
        sizeLevel="11"
        weightLevel="3"
        :class="isChangePos ? 'header-for-pos' : 'header'"
        color="grey-40"
      >
        Şube Seçiniz</VueText
      >
      <VueListView class="branch-list" dir="column">
        <div class="post-list-button" v-for="item in getPosList" :key="item.id">
          <VueButton
            :size="sizes.xLarge"
            :contentAlignment="constants.flexAlignment.center"
            :isSingleLine="false"
            outlined
            class="single-pos-item"
            :class="{ 'selected-pos': selectedPos.id == item.id }"
            @click.prevent="setPos(item)"
            >{{ item.posName }}</VueButton
          >
        </div>
      </VueListView>

      <div slot="footer" class="button-holder">
        <BrandButton
          class="submit-button"
          :size="sizes.xxLarge"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          :disabled="!selectedPos.id"
          @click.prevent="submitPos"
          >DEVAM ET</BrandButton
        >
      </div>
    </VueForm>
  </div>
</template>
<script>
import VueListView from '@/components/shared/VueListView/VueListView.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { mapGetters } from 'vuex';
import { Account } from '@/services/Api/index';
import StorageHelper from '@/utils/storageHelper';
import VueText from '@/components/shared/VueText/VueText.vue';
import AccountMixin from '@/utils/accountUtils.js';

export default {
  name: 'SelectPos',
  components: {
    VueButton,
    BrandButton,
    VueForm,
    VueListView,
    VueText,
  },
  mixins: [AccountMixin],
  data: () => {
    return {
      submitted: false,
      selectedPos: {},
    };
  },
  props: {
    isChangePos: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (!this.getPosList) {
      this.$router.push(RoutesLogin.SelectLoginType.path);
    }

    this.getPosList.forEach(f => {
      if (f.isCurrentPos) {
        this.selectedPos = f;
      }
    });
  },
  computed: {
    ...mapGetters('auth', ['getPosList']),
    ...mapGetters('app', ['getChangedPosToken']),
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    routes() {
      return {
        auth: RoutesAthentication,
      };
    },
    getIcon() {
      return ICON_VARIABLES.eye;
    },
  },
  methods: {
    setPos(item) {
      this.selectedPos = item;
    },
    async submitPos() {
      if (this.selectedPos.id) {
        if (this.isChangePos) {
          new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).set(
            this.getChangedPosToken,
          );
        }

        await Account.selectedPos({ posId: this.selectedPos.id }).then(res => {
          const { Data } = res ? res.data : { Data: null };

          if (Data) {
            const response = { ...Data, pos: [this.selectedPos] };

            const { token, user, refreshToken, pos, staffValidation } = response;

            this.$store.dispatch('auth/setAuthData', response).then(() => {
              if (token) {
                new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).set(token);
              }
              if (user) {
                new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).set(user);
              }
              if (refreshToken) {
                new StorageHelper({ id: process.env.VUE_APP_STORAGE_REFRESH_TOKEN_KEY }).set(
                  refreshToken.token,
                );
              }
              if (pos) {
                new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_POS_KEY }).set(pos[0]);
                this.accountLogin(pos[0].posChannel, staffValidation, user);
              }
            });
            this.$emit('closePopUp', staffValidation);
          }
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.header {
  padding-top: palette-space-level('60');
  padding-bottom: palette-space-level('10');
}
.header-for-pos {
  padding-top: palette-space-level('20');
  padding-bottom: palette-space-level('10');
}

.post-list-button {
  padding-top: palette-space-level('20');
}

.button-holder {
  padding-top: palette-space-level('40');
  .submit-button {
    border-radius: palette-radius-level('2');
  }
}

.selected-pos {
  background-color: palette-color-level('green', 30) !important;
  color: palette-color-level('white', 100) !important;
}

.single-pos-item {
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.branch-list {
  height: 450px;
  padding-right: palette-space-level('10');
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    width: 8px;
    border-radius: 5.5px;
    background-color: palette-color('grey-10');
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 5.5px;
    background-color: palette-color('grey-40');
  }
}
</style>
