<template>
  <div class="detail-card" :class="[isCollapsed ? 'detail-card collapsed' : 'detail-card shown']">
    <div class="title" @click="toggleMarathonCard">
      <div>Ödül Yağmuru Haftalık Performans</div>
      <VueIcon
        :class="[isCollapsed ? 'title collapsed' : 'title shown']"
        :width="icons.right.width"
        :height="icons.right.height"
        :iconName="icons.right.name"
        style="margin-left: 10px;"
        color="#ffffff"
      />
    </div>
    <div v-if="!isCollapsed" class="card-content">
      <div class="table-container">
        <table>
          <tr>
            <td class="column-header">Gün</td>
            <td class="column-header">Katılım Durumu</td>
          </tr>
          <tr v-for="(info, index) in weeklyMarathonInfo" :key="index">
            <td>
              {{ getDayOfWeek(info.date) }},<span> {{ getDayAndMonth(info.date) }}</span>
            </td>
            <td>
              <span v-if="info.participationStatus !== null">
                <VueIcon
                  :width="`${getIconInfo(info.participationStatus).width}`"
                  :height="`${getIconInfo(info.participationStatus).height}`"
                  :iconName="`${getIconInfo(info.participationStatus).iconName}`"
                />
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import Gamification from '@/services/Api/gamification';

export default {
  name: 'MarathonDetailCard',
  components: {
    VueIcon,
  },
  data() {
    return {
      isCollapsed: true,
      weeklyMarathonInfo: [],
    };
  },
  computed: {
    icons() {
      return ICON_VARIABLES;
    },
  },
  created() {
    this.getWeeklyMarathonInfo();
  },
  methods: {
    getWeeklyMarathonInfo() {
      Gamification.getWeeklyMarathonInfo().then(res => {
        if (!res?.data?.Data) return;
        const allMarathonInfo = res.data.Data.weeklyMarathonInfo.filter(info => {
          const currentDate = new Date(info.date);
          return currentDate.getDay() !== 0 && currentDate.getDay() !== 6;
        });

        this.weeklyMarathonInfo = allMarathonInfo.slice(0, 5);
      });
    },
    toggleMarathonCard() {
      this.isCollapsed = !this.isCollapsed;
    },
    getIconInfo(status) {
      const icon = status ? this.icons.iconCircleCheck : this.icons.iconCircleCross;

      return {
        width: icon.width,
        height: icon.height,
        iconName: icon.name,
      };
    },
    getDayOfWeek(dateString) {
      const options = { weekday: 'long' };
      const dayOfWeek = new Date(dateString).toLocaleDateString('tr-TR', options);
      return dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
    },
    getDayAndMonth(dateString) {
      const options = { day: 'numeric', month: 'long' };
      const dayAndMonth = new Date(dateString).toLocaleDateString('tr-TR', options);
      return dayAndMonth.charAt(0).toUpperCase() + dayAndMonth.slice(1);
    },
  },
};
</script>
<style lang="scss" scoped>
.detail-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background: linear-gradient(182deg, #ffa959 -23.76%, #ae4726 86.73%);

  &.collapsed {
    height: 50px;
  }
  &.shown {
    height: auto;
  }

  .card-content {
    padding: 0 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .title {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &.collapsed {
      transform: rotateZ(0deg);
    }
    &.shown {
      transform: rotateZ(90deg);
    }
  }

  .table-container {
    width: 100%;
    overflow-x: auto;
    margin: 10px 0 20px 0;
  }

  table {
    width: 100%;
  }

  td {
    color: #fff;
    text-align: left;
    min-height: 50px;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
    align-items: center;
    border-bottom: 1px solid #ffffff1a;
    span {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  td:first-child {
    border-right: 1px solid #ffffff1a;
  }

  tr:first-child td {
    border-top: none;
  }

  td:last-child {
    border-right: none;
    display: flex;
    justify-content: center;
  }

  .column-header {
    color: rgba(255, 255, 255, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
  }
}
</style>
