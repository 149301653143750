var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar"},[_c('div',[(_vm.getShapeVisible)?_c('div',{staticClass:"shape",on:{"click":function($event){return _vm.closePopup(
          {
            event: 'cEvent',
            category: 'Pop Up',
            action: 'Close',
            label: _vm.getPopups.popupName,
            value: 0,
            isNonInteraction: false,
          },
          { type: 'BGClose' }
        )}}}):_vm._e(),(_vm.customerPoints === 0 || _vm.customerPoints)?_c('div',{staticClass:"points-container",style:(['KACC', 'LAMP'].includes(_vm.constants.userType) || !_vm.isPartner
          ? 'visibility:hidden;'
          : 'visibility:visible;')},[_c('VueText',{attrs:{"sizeLevel":"6","weightLevel":"3"}},[_vm._v("Biriken Puanlarım")]),_c('div',{staticClass:"points-wrapper",on:{"click":_vm.goWallets}},[_c('VueText',{staticClass:"points",attrs:{"sizeLevel":"5","weightLevel":"3"}},[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.customerPoints)))]),_c('img',{attrs:{"src":require("@/assets/user/point-coins.png"),"alt":"user-points"}})],1)],1):_vm._e(),(!this.isImageRecognitionUser && !_vm.isLampUser)?_c('BrandCompetitionSummary'):_vm._e(),(!this.isImageRecognitionUser)?_c('WeatherCard'):_vm._e(),(!this.isImageRecognitionUser)?_c('MarathonCard'):_vm._e(),(!this.isImageRecognitionUser)?_c('RosetteCard'):_vm._e(),_c('MarathonPopup',{on:{"closePopup":_vm.closePopup}}),(!this.isImageRecognitionUser)?_c('DoWinCard',{staticClass:"dowin-card",style:(_vm.getDoWinCardStyle)},[_c('DoWinPopup',{on:{"closePopup":_vm.closePopup}})],1):_vm._e(),(_vm.isOtpAvailable)?_c('BrandButton',{staticClass:"otp-btn",attrs:{"as":"router-link","target":'/secure/tutun-akademi'},nativeOn:{"click":function($event){return _vm.tutunAkademiDataLayer.apply(null, arguments)}}}):_vm._e(),(_vm.chefShopAvailable && !_vm.isImageRecognitionUser && !_vm.isLampUser)?_c('ButtonVirtualVisit',{style:(_vm.getVirtualVisitButtonStyle),attrs:{"id":"chefs_shop_button"}}):_vm._e(),(!this.isImageRecognitionUser)?_c('ButtonChatbot'):_vm._e(),(_vm.customerDetail && _vm.customerDetail.isEOrderCustomer)?_c('ButtonPreOrder'):_vm._e(),_c('VirtualVisitPopup',{on:{"closePopup":_vm.closePopup}})],1),_c('UndeliveredAtomCallPopup',{on:{"closePopup":_vm.closePopup}}),_c('AtomSurveyPopup',{on:{"closePopup":_vm.closePopup}}),_c('RosettePopup',{on:{"closePopup":_vm.closePopup}}),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ad-wrapper-small"},[_c('div',{attrs:{"id":"div-gpt-ad-1587046855075-0"}})])}]

export { render, staticRenderFns }