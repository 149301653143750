<template>
  <div class="wrapper" @click="openEOrder">
    <img :src="imgSrc" alt="chatbot" />
    <div class="text-content-wrapper">
      <div class="text-content">
        <VueText sizeLevel="7" weightLevel="2">Ön-Sipariş Ver</VueText>
      </div>
    </div>
  </div>
</template>
<script>
import VueText from 'VueText';
import { Common } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';
export default {
  name: 'ButtonPreOrder',
  components: {
    VueText,
  },
  mixins: [gtmUtils],
  computed: {
    imgSrc() {
      return require('../../../assets/button/preorder-cart.svg');
    },
  },
  methods: {
    openEOrder() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('on_siparis_click', {
        location: 'sidebar',
      });
      Common.getEcommerceUrl().then(res => {
        const {
          Data: { url },
        } = res.data;
        if (url) {
          window.location = url;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: bottom;
  background: url('~@/assets/button/preorder-bg.svg');
  margin-bottom: palette-space-level(10) !important;
  background-repeat: no-repeat;
  background-position-y: bottom;
  cursor: pointer;
  > img {
    margin-left: palette-space-level(8) !important;
  }
  .text-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: palette-space-level(20);
    padding-left: palette-space-level(10);
    .text-content {
      display: flex;
      flex-direction: row;
      user-select: none;
      color: #fff;
      p {
        &:first-child {
          margin-bottom: palette-space-level(3);
        }
      }
    }
  }
}
</style>
