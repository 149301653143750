<template>
  <div
    v-if="weatherData"
    :class="[isCollapsed ? 'weatherContainer collapsed' : 'weatherContainer shown']"
  >
    <div class="weatherContainer-weatherHeader" @click="toggleWeather()">
      <span>Hava Durumu</span>
      <button
        type="button"
        :class="[
          isCollapsed
            ? 'weatherContainer-weatherHeader-button collapsed'
            : 'weatherContainer-weatherHeader-button shown',
        ]"
      >
        <VueIcon
          :width="icons.right.width"
          :height="icons.right.height"
          :iconName="icons.right.name"
          color="#ffffff"
        ></VueIcon>
      </button>
    </div>
    <div class="weatherContainer-weatherContent">
      <div class="weatherContainer-weatherContent-leftPane">
        <div class="weatherContainer-weatherContent-leftPane-city">{{ city }}</div>
        <div class="weatherContainer-weatherContent-leftPane-date">
          {{ date.weekDay }},{{ date.day }} {{ date.month }}
        </div>
        <div class="weatherContainer-weatherContent-leftPane-temp">
          {{ Math.trunc(weatherData.temp) }} &#176;
        </div>
        <div class="weatherContainer-weatherContent-leftPane-sky">
          <img :src="weatherData.icon" />
          {{ description }}
        </div>
      </div>
      <div class="weatherContainer-weatherContent-rightPane">
        <div class="weatherContainer-weatherContent-rightPane-wind">
          <img src="~@/assets/icons/wind.svg" alt="" /> Rüzgar:
          <span> {{ Math.trunc(weatherData.wind) }} km/s</span>
        </div>
        <div class="weatherContainer-weatherContent-rightPane-rain">
          <img src="~@/assets/icons/rain.svg" alt="" /> Yağış:
          <span> {{ weatherData.rain }} mm</span>
        </div>
        <div class="weatherContainer-weatherContent-rightPane-feelsLike">
          <img src="~@/assets/icons/feelsLike.svg" alt="" /> Hissedilen:
          <span> {{ Math.trunc(weatherData.feelsLike) }} &#176;</span>
        </div>
        <div class="weatherContainer-weatherContent-rightPane-humidity">
          <img src="~@/assets/icons/humidity.svg" alt="" /> Nem Oranı:
          <span> %{{ weatherData.humidity }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Common } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';
import { mapGetters } from 'vuex';

export default {
  name: 'WeatherCard',
  components: {
    VueIcon,
  },
  mixins: [gtmUtils],
  data() {
    return {
      weekdays: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
      months: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ],
      weatherData: null,
      isCollapsed: true,
      hasBeenUncollapsed: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['getUserDetails']),
    city() {
      return this.getUserDetails?.city;
    },
    icons() {
      return ICON_VARIABLES;
    },
    date() {
      let resDate = new Date();
      return {
        weekDay: this.weekdays[resDate.getDay()],
        day: resDate.getDate(),
        month: this.months[resDate.getMonth()],
      };
    },
    description() {
      return this.weatherData.description.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  },
  created() {
    this.getWeatherData();
  },
  methods: {
    getWeatherData() {
      Common.getWeather()
        .then(res => {
          if (res?.data?.Data) {
            this.weatherData = res.data.Data;
          }
        })
        .catch(error => {
          return error;
        });
    },
    toggleWeather() {
      this.isCollapsed = !this.isCollapsed;
      if (!this.isCollapsed && !this.hasBeenUncollapsed) {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('hava_durumu');
        this.hasBeenUncollapsed = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.weatherContainer {
  border-radius: 5px;
  transition: height 0.5s ease-out;
  overflow: hidden;
  height: 205px;
  font-family: 'Roboto', sans-serif;
  color: white;
  margin: 10px 0px 10px 0px;
  padding: 0px 15px 0px 15px;
  background: linear-gradient(#7a9cff, #3768f4);
  &.collapsed {
    height: 50px;
  }
  &.shown {
    height: 205px;
  }
  &-weatherHeader {
    height: 50px;
    padding: 15px 0px 15px 0px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &-button {
      transition: transform 0.3s ease-out;
      cursor: pointer;
      background: none;
      border: none;
      &.collapsed {
        transform: rotateZ(0deg);
      }
      &.shown {
        transform: rotateZ(90deg);
      }
    }
  }
  &-weatherContent {
    padding-bottom: 15px;
    column-gap: 50px;
    display: grid;
    &-leftPane {
      grid-column: 1;
      position: relative;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      margin-right: -25px;
      padding-right: 25px; /* size of gutter */
      div {
        margin-bottom: 5px;
      }
      &-date {
        color: rgba(255, 255, 255, 0.7);
        font-size: 10px;
      }
      &-temp {
        font-size: 24px;
      }
      &-sky {
        font-size: 12px;
        display: flex;
        justify-content: center;
        margin-bottom: 25px !important;
        position: absolute;
        bottom: 0;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
    }
    &-rightPane {
      grid-column: 2;
      div {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-bottom: 20px;
        span {
          margin-left: 5px;
          color: rgba(255, 255, 255, 0.7);
        }
        img {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
