<template>
  <div class="footer-container">
    <div class="content-layout-fixer" v-if="showTypeVisual && !this.isUserKacc">
      <div class="footer-upper">
        <div class="footer-content">
          <VueText sizeLevel="4" weightLevel="3" color="grey-30">PHILIP MORRIS</VueText>
          <VueText sizeLevel="10" weightLevel="4" color="white-100"
            >Ön sipariş işlemleriniz artık daha kolay!</VueText
          >
          <VueText sizeLevel="5" weightLevel="1" color="grey-20"
            >Yenilenen web sayfamız ile ön siparişleriniz ve mağazacılık yönetiminiz artık çok daha
            kolay. Siz de
            <a :href="`//` + 'www.pmaktif.com'" target="_blank" class="link">www.pmaktif.com</a>
            adresine giderek avantajlardan yararlanın.</VueText
          >
        </div>
        <img class="bg-big" src="@/assets/logos/newFooterBig.png" alt="footer-big" />
      </div>
    </div>
    <div class="footer-lower">
      <div class="content-layout-fixer">
        <div class="content-wrapper">
          <div class="left-content">
            <div class="footer-logo">
              <BrandLogo
                :hasBorder="false"
                :width="150"
                :height="80"
                :image="require('@/assets/logos/philip-morris-logo.png')"
                class="logo"
              />
            </div>
            <!-- <div class="cr-content">
              <VueText sizeLevel="4" weightLevel="3" color="grey-30"
                >Copyright © 2020 PHILIP MORRIS</VueText
              >
              <VueText class="cr-info" sizeLevel="3" weightLevel="2" color="grey-30"
                >PMAktif portalı sigara satış ruhsatına sahip satış noktaları için hazırlanmıştır.
                <span class="cr-links" v-for="item in footerLeftMenu" :key="item.id">
                  <router-link :to="{ path: item.link }">{{ item.title }}</router-link>
                </span>
              </VueText>
            </div> -->
            <div class="cr-content" v-if="!isPartner">
              <VueText sizeLevel="4" weightLevel="3" color="grey-30"
                >Copyright © 2020 PHILIP MORRIS</VueText
              >
              <VueText class="cr-info" sizeLevel="3" weightLevel="2" color="grey-30">
                PMAktif portal, tacir/esnaf niteliği taşıyan satış noktalarına yönelik olarak
                hazırlanmıştır. Siteyi kullanmak istiyorsanız, satış temsilciniz ile iletişime
                geçiniz.
                <div>
                  <span class="cr-links" v-for="item in footerLeftMenu" :key="item.id">
                    <router-link :to="{ path: item.link }">{{ item.title }}</router-link>
                  </span>
                </div>
              </VueText>
            </div>
            <div class="cr-content" v-else>
              <VueText sizeLevel="4" weightLevel="3" color="grey-30"
                >Copyright © 2020 PHILIP MORRIS</VueText
              >
              <VueText class="cr-info" sizeLevel="3" weightLevel="2" color="grey-30">
                PMAktif portal, tacir/esnaf niteliği taşıyan satış noktalarına yönelik olarak
                hazırlanmıştır. Siteyi kullanmak istiyorsanız, satış temsilciniz ile iletişime
                geçiniz.
                <span class="cr-links" v-for="item in footerLeftMenu" :key="item.id">
                  <router-link :to="{ path: item.link }">{{ item.title }}</router-link>
                </span>
              </VueText>
            </div>
          </div>
          <div class="right-content" v-if="!this.isImageRecognitionUser">
            <ul v-show="footerItem.links[0]" v-for="footerItem in footerMenu" :key="footerItem.id">
              <li>{{ footerItem.title }}</li>
              <li v-for="item in footerItem.links" :key="item.id">
                <a href="#" @click.prevent="getGiftCatalogUrl()" v-if="item.link === 'm'">{{
                  item.linkTitle
                }}</a>
                <router-link
                  @click.native="dataLayerEvent(footerItem.title, item.linkTitle, item.link)"
                  :to="{ path: item.link }"
                  v-else
                  >{{ item.linkTitle }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div>
          <hr />
          <div class="legal-warning">SİGARA İÇMEK ÖLDÜRÜR!</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueText from 'VueText';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSecure from '@/router/routes/RoutesSecure.js';
import RoutesStatic from '@/router/routes/secure/RoutesStatic';
import RoutesSettings from '@/router/routes/secure/RoutesSettings';
import { Common } from '@/services/Api/index';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import StorageHelper from '@/utils/storageHelper';
import { mapGetters } from 'vuex';
import AccountMixin from '@/utils/accountUtils.js';
import StorageProps from '@/mixins/storageProps.js';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'FooterBig',
  mixins: [AccountMixin, StorageProps, gtmUtils],
  components: {
    VueText,
    BrandLogo,
  },
  data() {
    return {
      footerLeftMenu: [
        {
          id: 0,
          title: 'Yasal Şartlar',
          link: `${RoutesRoot.Secure.path}/${RoutesSecure.Settings.path}/${RoutesSettings.LegalWarning.path}`,
        },
        {
          id: 1,
          title: 'Gizlilik Bildirimi',
          link: `${RoutesRoot.Secure.path}/${RoutesSecure.Settings.path}/${RoutesSettings.DataPolicy.path}`,
        },
        {
          id: 2,
          title: 'Çerez Politikası',
          link: `${RoutesRoot.Secure.path}/${RoutesSecure.Statics.path}/${RoutesStatic.CookiePolicy.path}`,
        },
      ],
      footerMenu: [
        {
          id: 0,
          title: 'Kurumsal',
          links: [
            {
              id: 0,
              linkTitle: 'Philip Morris',
              link: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/pmi`,
            },
            {
              id: 1,
              linkTitle: 'Performans Takibi',
              link: `${RoutesRoot.Secure.path}/${RoutesSecure.ManageMyBusiness.path}`,
            },
          ],
        },
        {
          id: 1,
          title: 'PM Aktif',
          links: [
            {
              id: 0,
              linkTitle: 'Oyna Kazan',
              link: `${RoutesRoot.Secure.path}/${RoutesSecure.PlayAndWin.path}`,
            },
            {
              id: 1,
              linkTitle: 'Yap Kazan',
              link: `${RoutesRoot.Secure.path}/${RoutesSecure.DoAndWin.path}`,
            },
            {
              id: 2,
              linkTitle: 'Akademi',
              link: `${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}`,
            },
            {
              id: 3,
              linkTitle: 'Yaşam',
              link: `${RoutesRoot.Secure.path}/${RoutesSecure.Fun.path}`,
            },
          ],
        },
        {
          id: 2,
          title: 'Profil',
          links: [
            {
              id: 0,
              linkTitle: 'Cüzdanlarım',
              link: `${RoutesRoot.Secure.path}/${RoutesSecure.MyWallets.path}`,
            },
            {
              id: 1,
              linkTitle: 'Hediye Kataloğu',
              link: 'm',
            },
            {
              id: 2,
              linkTitle: 'Kampanyalar',
              link: `${RoutesRoot.Secure.path}/${RoutesSecure.Campaigns.path}`,
            },
          ],
        },
      ],
      showTypeVisual: true,
    };
  },
  computed: {
    ...mapGetters('auth', ['getUserModel']),
    isImageRecognitionUser() {
      return this.getUserModel?.isImageRecognitionUser;
    },
  },
  watch: {
    $route: {
      handler(newVal) {
        this.showTypeVisual =
          newVal.name === RoutesSecure.Fun.name || newVal.name === RoutesSecure.Feed.name;
      },
    },
  },

  methods: {
    getGiftCatalogUrl() {
      Common.getGiftCatalogLink().then(res => {
        const {
          Data: { url },
        } = res.data;
        if (url) {
          window.location = url;
        }
      });
    },
    checkUserType() {
      let userType = new StorageHelper({ id: process.env.VUE_APP_STORAGE_USER_ACCOUNT_TYPE }).get();
      if (userType === 'KACC') {
        this.footerMenu[2].links.splice(0, 2);
      }
      if (!this.isPartner) {
        this.footerMenu[0].links.splice(0, 2);
        this.footerMenu[2].links.splice(0, 2);
        this.footerMenu[1].links.splice(0, 3);
      }
      if (this.isLampUser) {
        this.footerMenu[2].links = [];
      }
    },
    checkMenu() {
      let cachedMenuData = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_MENU_ITEMS,
      }).get();
      if (!cachedMenuData || !cachedMenuData.menuItems) {
        Common.getMenu().then(res => {
          if (!res.data.Data) return;
          if (res && res.data && res.data.Data) {
            this.cachedMenuData = res.data.Data;
            this.checkCampaigns(this.cachedMenuData);
            this.checkGiftCatalog(this.cachedMenuData);
          }
        });
      } else {
        this.checkCampaigns(cachedMenuData);
        this.checkGiftCatalog(cachedMenuData);
      }
    },
    checkCampaigns(cachedMenuData) {
      let campaignsAvailable = false;
      cachedMenuData?.menuItems?.forEach(parent => {
        parent?.children?.forEach(child => {
          if (child.name === 'Kampanyalar' || child.id === 8 || child.screenCode === 8) {
            campaignsAvailable = true;
          }
        });
      });
      if (!campaignsAvailable) {
        this.footerMenu[2].links.pop();
      }
    },
    checkGiftCatalog(cachedMenuData) {
      let giftCatalogAvailable = false;
      giftCatalogAvailable = cachedMenuData?.menuItems?.filter(f => f.screenCode === 7).length > 0;
      if (!giftCatalogAvailable) {
        this.footerMenu[2].links.splice(1, 1);
      }
    },
    dataLayerEvent(category, item, link) {
      if (item === 'Yap Kazan') {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('yap_kazan', {
          location: 'Footer Menu',
        });
      } else if (item === 'Cüzdanlarım') {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('cuzdanlarim', {
          location: 'Footer Menu',
        });
      } else {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('footer_menu_click', {
          header: category,
          content: item,
          path: link,
        });
      }
    },
  },
  mounted() {
    this.showTypeVisual =
      this.$route.name === RoutesSecure.Fun.name || this.$route.name === RoutesSecure.Feed.name;
    this.checkMenu();
    this.checkUserType();
  },
};
</script>
<style lang="scss" scoped>
.footer-logo {
  max-width: 142px;
  /deep/ .logo {
    margin-left: -6px;
  }
  img {
    width: 100%;
    height: auto;
  }
}
.footer-container {
  .footer-upper {
    .footer-content {
      position: absolute;
      max-width: 520px;
      padding-left: palette-space-level(20);
      margin-top: 150px;
      p {
        padding: palette-space-level(10);
      }
    }
    .bg-big {
      display: block;
    }
  }
  .footer-lower {
    height: 330px;
    background-color: palette-color-level('grey', 10);
    padding-top: palette-space-level(30);
    padding-left: palette-space-level(20);
    padding-right: palette-space-level(20);
    hr {
      border-top: 1px solid palette-color-level('grey', 20);
    }
    .content-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .left-content {
      padding-left: palette-space-level('20');
      margin-bottom: palette-space-level('40');

      .cr-content {
        max-width: 330px;
      }
      p {
        margin-bottom: palette-space-level(10);
        margin-top: palette-space-level(10);
      }
      .cr-info {
        position: relative;
        .cr-links {
          margin-left: palette-space-level(5);
        }
        a {
          font-size: palette-font-size-level(1);
          font-weight: 600;
          margin-left: palette-space-level(10);
          color: palette-color-level('grey', '30');
          text-decoration: underline;
        }
      }
    }
    .right-content {
      display: flex;
      flex-direction: row;
      padding-right: palette-space-level(20);
      ul {
        padding: 0 palette-space-level(20) palette-space-level(20) palette-space-level(20);
        list-style: none;
        li {
          padding: palette-space-level(10);
          &:first-child {
            padding-top: 0;
            font-size: palette-font-size-level(5);
            font-weight: 600;
          }
          a {
            font-size: palette-font-size-level(4);
            font-weight: 300;
            color: palette-color-level('grey', 30);
            text-decoration: none;
          }
        }
      }
    }
    .legal-warning {
      font-size: palette-font-size-level(6);
      font-weight: 900;
      border: solid 2px black;
      text-align: center;
      padding-top: palette-space-level(10);
      padding-bottom: palette-space-level(10);
      margin: palette-space-level(20);
      background-color: palette-color-level('white', 100);
    }
  }
  .link {
    text-decoration: none;
    color: white;
  }
}
</style>
