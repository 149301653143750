import { HttpService } from '@/services/HttpService';

class DynamicPopups {
  subdomain = '/dynamicPopup';

  getAllDynamicPopup() {
    return HttpService.get(`${this.subdomain}/getAll`);
  }

  viewlogDynamicPopup(request) {
    return HttpService.post(`${this.subdomain}/viewlog`, request);
  }
}

export default new DynamicPopups();
